import { NotificationService } from '@app/components'
import {
  EnergyBiddingZone,
  FerroOptimizationOptions,
  SpotPriceCalculationStatus
} from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'
import { LanguageSystemSettings } from '@app/language/language.interface'
import {
  FeatureFlagService,
  FeatureFlagServiceName,
  FerroConfiguration,
  FerroConfigurationName,
  LanguageService,
  LanguageServiceName
} from '@app/service'
import { StateService } from '@uirouter/core'
import type { IComponentOptions, IFormController } from 'angular'
import { copy, material } from 'angular'
import { isNumber } from 'lodash'
import { DateTime } from 'luxon'
import { systemSettingsStates } from '../../system-settings.routing.config'
import { en as en2 } from '../ems-config-form/ems-config.language'
import { SpotPriceEnglish, SpotPriceFeedbackType, SpotPriceSwedish } from './spot-price-common'
import { SpotPriceFeedback } from './spot-price-feedback'
import feedbackTemplate from './spot-price-feedback.html'
import templateUrl from './spotprice.html'
import './spotprice.scss'

const StatusClasses: { [key in SpotPriceCalculationStatus]: string } = {
  FAILURE: 'spot-price-failed',
  CALCULATED: 'spot-price-calculated',
  PRICE_DIFF_TOO_LOW: 'spot-price-prife-diff-to-low'
}

export class SpotPriceOptionsController {
  isEnabled = false
  currencyCode: string
  l2: LanguageSystemSettings
  l: typeof SpotPriceEnglish
  l3 = en2
  options: Partial<FerroOptimizationOptions> & { status?: string; error?: string; cls?: string }
  energyBiddingZone: EnergyBiddingZone
  enableLimitImportThreshold = false
  limitImportThreshold: number
  feedbackEnabled = false

  constructor(
    private fc: FerroConfiguration,
    private features: FeatureFlagService,
    private state: StateService,
    private notifications: NotificationService,
    private language: LanguageService,
    private $mdDialog: material.IDialogService
  ) {
    this.l = this.language.language === 'se' ? SpotPriceSwedish : SpotPriceEnglish
    this.l2 = this.fc.language.SystemSettings
    this.isEnabled = this.features.spotPriceIsEnabled()
    this.feedbackEnabled = this.features.useSpotPriceFeedback()
  }

  async $onInit() {
    const res = await runQuery.getFacilityOptimizationInformationQuery({
      variables: {
        facilityId: +this.fc.facility.id
      }
    })
    const info = res?.data?.facility
    this.currencyCode = info?.misc?.currencyCode
    this.energyBiddingZone = info?.location?.energyBiddingZone

    const lastCalculationStatus = info?.optimization?.lastCalculationStatus
    const lastCalculationError = info?.optimization?.lastCalculationError
    if (!info?.optimization) {
      this.options = {}
    } else {
      this.options = copy(info?.optimization)
      // COnvert to kW from watt.
      this.limitImportThreshold = isNumber(this.options?.limitImportThreshold)
        ? this.options.limitImportThreshold / 1000
        : null
      this.options.status = this.l[lastCalculationStatus]
      this.options.error = this.l[lastCalculationError]
      this.options.lastCalculationAttempt = this.options?.lastCalculationAttempt
        ? DateTime.fromISO(this.options.lastCalculationAttempt, {
            zone: this.fc.facility.timezone
          }).toFormat('yyyy-MM-dd HH:mm')
        : null
      this.options.lastCalculatedDay = this.options?.lastCalculatedDay
        ? DateTime.fromISO(this.options.lastCalculatedDay, {
            zone: this.fc.facility.timezone
          }).toFormat('yyyy-MM-dd')
        : null
      this.options.cls = StatusClasses[lastCalculationStatus]
    }

    if (isNumber(this.limitImportThreshold)) {
      this.enableLimitImportThreshold = true
    }
  }

  async upsert(form: IFormController, initial: boolean) {
    if (form.$valid) {
      const res = await runQuery.upsertOptimizationOptionsMutation({
        variables: {
          enable: true,
          facilityId: +this.fc.facility.id,
          minimumEarnings: this.options.minimumEarnings,
          limitImportThreshold: this.enableLimitImportThreshold
            ? // Convert back to Watts
              this.limitImportThreshold * 1000
            : null
        }
      })
      const data = res?.data?.upsertOneOptimizationOptions
      if (data?.success) {
        this.notifications.onSuccess(
          initial ? this.l.SUCCESS_FULLY_CREATED_SPOT_PRICE : this.l.SUCCESSFULLY_UPDATED_SPOT_PRICE
        )
        this.options = copy(data?.options)
      } else if (!data?.success && data?.errors) {
        this.notifications.onError(this.l[data?.errors?.at(0).__typename])
      } else {
        this.notifications.onError(this.l2.ERROR_OCCURRED)
      }
    }
  }

  async disable($event: any) {
    const yes = await this.fc
      .confirm(
        this.l.DISABLE_SPOT_PRICE_TITLE,
        this.l.DISABLE_SPOT_PRICE_DESCRIPTION,
        this.l.ACCEPT_DISABLE,
        this.l.DONT_DISABLE,
        $event
      )
      .catch(() => false)
    if (yes) {
      const res = await runQuery.disableOptimizationControlMutation({
        variables: {
          facilityId: +this.fc.facility.id
        }
      })
      const data = res?.data?.disableOptimizationControl
      if (data?.success) {
        this.notifications.onSuccess(this.l.SUCCESSFULLY_DISABLED_SPOT_PRICE)
        this.options = copy(data?.options)
        if (this.feedbackEnabled) {
          this.openFeedback(SpotPriceFeedbackType.DEACTIVATED)
        }
      } else {
        this.notifications.onError(this.l.ERROR_DISABLE_SPOT_PRICE)
      }
    }
  }

  gotoSystemSettings() {
    this.state.go(systemSettingsStates.systemInformation, { '#': 'energyBiddingZoneContainer' })
  }
  onEnabledLimitChanged() {
    if (!this.enableLimitImportThreshold) {
      this.limitImportThreshold = null
    }
  }

  async openFeedback(type: SpotPriceFeedbackType) {
    this.$mdDialog
      .show({
        controller: SpotPriceFeedback,
        templateUrl: feedbackTemplate,
        parent: angular.element(document.body),
        controllerAs: 'vm',
        locals: {
          type,
          currentLanguage: this.l
        }
      })
      .catch(() => {})
  }
}

SpotPriceOptionsController.$inject = [
  FerroConfigurationName,
  FeatureFlagServiceName,
  '$state',
  'notificationService',
  LanguageServiceName,
  '$mdDialog'
]

export const SpotPriceOptionsComponent: IComponentOptions = {
  controller: SpotPriceOptionsController,
  templateUrl
}
